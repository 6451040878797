(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("node-fetch"), require("form-data"));
	else if(typeof define === 'function' && define.amd)
		define(["node-fetch", "form-data"], factory);
	else if(typeof exports === 'object')
		exports["ConnectyCube"] = factory(require("node-fetch"), require("form-data"));
	else
		root["ConnectyCube"] = factory(root["node-fetch"], root["form-data"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__8849__, __WEBPACK_EXTERNAL_MODULE__6245__) => {
return 